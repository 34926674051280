<template>
  <v-card class="mx-auto" :loading="ldg" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'restitutions',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col v-if="data" cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="cyan darken-3"
                dark
                :loading="btn_ldg"
                @click.prevent="downloadFile('pdf')"
              >
                <v-icon> mdi-file-download </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar PDF'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal darken-2"
                dark
                :loading="btn_ldg"
                @click.prevent="downloadFile('xml')"
              >
                <v-icon> mdi-xml </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar XML'" />
          </v-tooltip>
          <v-tooltip v-if="data.paid_date == null" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="warning"
                @click.prevent="insurancePaidDlg"
              >
                <v-icon> mdi-currency-usd </v-icon>
              </v-btn>
            </template>
            <span v-text="'Reg. pago'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row dense>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Restitución" :value="data.folio" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Tipo" :value="data.type" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Creación" :value="data.created_at" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Creado por" :value="data.created_by.email" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Contratante" :value="data.contractor.name" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Folio Fact." :value="data.stamp_folio" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Monto" :value="numberFormat(data.amount)" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Registros" :value="data.restitution_items.length" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData
            label="F. pago"
            :value="data.paid_date ? data.paid_date : 'NO REG.'"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData
            label="Payment ID"
            :value="data.payment_id ? data.payment_id : 'NO REG.'"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="tbl_srch"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_hdrs"
            :search="tbl_srch"
            :items="data.restitution_items"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.insured_full_name="{ item, index }">
              <span :class="item.insured_id ? '' : 'orange--text'">
                {{ item.insured_full_name }}
              </span>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.action="{ item }"> </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="insurance_paid_dlg"
      scrollable
      persistent
      max-width="300px"
    >
      <v-card
        tile
        :disabled="insurance_paid_dlg_ldg"
        :loading="insurance_paid_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Pago aseguradora </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_paid_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_paid">
          <v-form v-on:submit.prevent ref="insurance_paid_form" lazy-validation>
            <v-row dense class="pt-5">
              <v-col cols="12">
                <DatePicker
                  label="F. Pago"
                  :model.sync="insurance_paid.paid_date"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Payment ID*"
                  v-model="insurance_paid.payment_id"
                  type="text"
                  dense
                  :rules="rules.required"
                  maxlength="20"
                  counter
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  small
                  color="success"
                  @click.prevent="insurancePaidSave"
                >
                  Enviar
                  <v-icon small right> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  base64ToArrayBuffer,
  dateTimeToFile,
  dateTimeNow,
  rules,
  msgConfirm,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";

export default {
  components: {
    FaqDlg,
    ViewData,
    DatePicker,
  },
  data() {
    return {
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      rules: rules(),
      ldg: false,
      data: null,
      tbl_srch: "",
      tbl_hdrs: [
        {
          value: "index",
          text: "#",
          sortable: false,
          filterable: false,
          width: "45",
        },
        {
          value: "folio",
          text: "Folio",
          filterable: true,
        },
        {
          value: "insured_enrollment",
          text: "ID SM",
          filterable: true,
        },
        {
          value: "insured_full_name",
          text: "Asegurado",
          filterable: true,
        },
        {
          value: "provider_name",
          text: "Proveedor | Médico",
          filterable: true,
        },
        {
          value: "amount",
          text: "Monto",
          filterable: true,
        },
        {
          value: "action",
          text: "",
          sortable: false,
          filterable: false,
          width: "55",
        },
      ],
      btn_ldg: false,
      insurance_paid: null,
      insurance_paid_dlg: false,
      insurance_paid_dlg_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    getData() {
      this.ldg = true;

      Axios.get(
        URL_API + "/restitutions/" + this.id,
        headersToken(this.login.token)
      ).then((res) => {
        this.data = res.data.data;
        this.ldg = false;
      });
    },

    downloadFile(ext) {
      this.btn_ldg = true;

      Axios.get(
        URL_API +
          "/bills/stamped/" +
          this.data.stamp_id +
          "/files/" +
          ext +
          "/" +
          (this.data.is_rha ? "1" : "2"),
        headersToken(this.login.token)
      ).then((res) => {
        this.$swal.fire(
          msgAlert(res.data.success ? "success" : "error", res.data.message)
        );

        if (res.data.success) {
          const blob = new Blob([base64ToArrayBuffer(res.data.data)], {
            type: "application/" + ext,
          });
          let downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download =
            "SM_bill" + this.data.stamp_id + "_" + dateTimeToFile() + "." + ext;
          downloadLink.click();
        } else {
          console.log(res.data.message);
        }

        this.btn_ldg = false;
      });
    },

    insurancePaidDlg() {
      this.insurance_paid = {
        id: this.data.id,
        paid_date: dateTimeNow().substring(0, 10),
        payment_id: "",
      };
      this.insurance_paid_dlg_ldg = false;
      this.insurance_paid_dlg = true;
    },

    insurancePaidSave() {
      if (this.$refs.insurance_paid_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma enviar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.insurance_paid_dlg_ldg = true;

              Axios.post(
                URL_API + "/restitutions/insurance/paid",
                this.insurance_paid,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.insurance_paid_dlg = false;
                  this.getData();
                } else {
                  console.log(res.data.message);
                }

                this.insurance_paid_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>